var m = (function() {

	function setupScroll() {
		$(document).on('click', 'nav a', function(e) {
			var target = $(this).attr('href');

			if (target.indexOf('http://') !== -1) {
				return true;
			}

			e.preventDefault();

			if ($(target).length) {
				var box = document.getElementById(target.substr(1, target.length)),
					top = box.offsetTop;

				$('.navbar-collapse').collapse('hide');

				if (target === '#intro') {
					top = 0;
				} else if (target === '#trailer') {
					top = top + 300;
				} else if (target === '#testimonials') {
					top = top - 200;
				}
				
				$('html, body').animate({scrollTop: top});
			} else { 
				window.location = target; 
			}
		});
	}

	function setupPopup() {
		$(document).on('click', '.play-button', function() {
			$('<div class="popup"><div class="content"><span class="close">X</span><iframe src="https://www.youtube.com/embed/7mvRj6QNXek?rel=0&hd=1&autoplay=1" frameborder="0" allowfullscreen></iframe></div></div>"').appendTo('body');
			$(document).on('click', '.close', function() {
				$('.popup').fadeOut('fast', function() {
					$(this).remove();
				})
			});
		});
	}

	function setupSharing() {
		$(document).on('click', '#trailer .btn', function(e) {
			if ($(this).hasClass('email')) {
				return true;
			}
			
			e.preventDefault();

			window.open(
				$(this).attr('href'),
				'',
				'menubar=no,location=no,resizable=no,scrollbars=no,status=no'
			);

			return false;
		});
	}

	return {
		init: function() {
			setupScroll();
			setupPopup();
			setupSharing();
		}
	}
})();